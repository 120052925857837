/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import sendIcon from "../../assets/images/icons/Icon feather-send.png";
import userChatIcon from "../../assets/images/icons/Icon awesome-user-circle.png";
import io from "socket.io-client";
import Header from "../Common/Header";
import pdfIcon from "../../assets/images/icons/pdf.png";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import moment from "moment";
import MobMenuBar from "../Common/MobMenuBar";
import SideBarMenu from "../Common/SideBarMenu";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import {
  combineDateTime,
  get15MinutesBefore,
  get1HourFuture,
  getInitialsLetters,
} from "../Common/Function";
import { socket } from "../../socket";
import bellIcon from "../../assets/images/icons/Icon ionic-ios-notifications-outline.png";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";

const ChatBox = () => {
  const navigate = useNavigate();
  const [room] = useState("chatmessage");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [loadingChat, setLoadingChat] = useState(false);
  const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
  const { GroupId } = useParams();


  // ***************** Activity List ******************

  const [EventList, setEventList] = useState([]);

  const EventListFun = () => {
    try {
      API.Event.EventList({
        GroupId: GroupId,
      }).then((response) => {
        // console.log("Event List  ===>", response?.data?.data_all);
        setEventList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };
  // ***************** Resources list ******************

  const [MediaList, setMediaList] = useState([]);

  const MediaListFun = () => {
    try {
      API.Event.MediaList({
        GroupId: GroupId,
      }).then((response) => {
        setMediaList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // **************** Chat Functionality Starts *********************

  const getGropAllChats = () => {
    setLoadingChat(true);
    API.Group.GroupsChatList({
      group_id: GroupId,
    })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log("Msg===>", response.data.data_all);
          setMessageList(response.data.data_all);
          setLoadingChat(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingChat(false);
      });
  };

  //socket .io
  // const sendMessage = async () => {
  //   if (currMessage !== "") {
  //     const messageData = {
  //       room_name: room,
  //       author: `${UserData?.userdata?.fullname}`,
  //       user_id: UserData?.userdata?._id,
  //       group_id: GroupId,
  //       message: currMessage,
  //       time:
  //         new Date(Date.now()).getHours() +
  //         ":" +
  //         new Date(Date.now()).getMinutes(),
  //     };
  //     await socket.emit(room, messageData);
  //   }
  //   setCurrMessage("");
  // };

  const sendMessage = async () => {
    if (!currMessage || currMessage.trim() === "") return; // Check if currMessage is empty or only whitespace

    if (!UserData || !UserData.userdata) return; // Check if UserData is defined

    const { userdata } = UserData; // Destructure UserData

    const now = new Date(); // Create a single Date instance

    const messageData = {
      room_name: room,
      author: `${userdata.fullname}`,
      user_id: userdata._id,
      group_id: GroupId,
      message: currMessage.trim(), // Trim whitespace from currMessage
      time: `${now.getHours()}:${now.getMinutes()}`, // Use the single Date instance
    };

    await socket.emit(room, messageData);
    setCurrMessage("");
  };

  // useEffect(() => {
  //   socket.on(room, (data) => {
  //     if (GroupId === data.group_id) {
  //       setMessageList((list) => [...list, data]);
  //     }
  //   });
  //   return () => {
  //     // socket.removeListener(room);
  //     socket.off(room); // Remove the listener when the component unmounts or the effect is re-run
  //   };
  // }, [socket]);

  useEffect(() => {
    if (!socket || !room || !GroupId) return;

    const handleData = (data) => {
      if (GroupId === data.group_id) {
        setMessageList((list) => [...list, data]);
      }
    };

    // Attach the event listener for incoming data
    socket.on(room, handleData);

    // Cleanup function to detach the listener and disconnect the socket
    return () => {
      socket.off(room); // Remove the listener
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, room, GroupId]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };

  //get Previous chat data
  useEffect(() => {
    getGropAllChats();
    EventListFun();
    MediaListFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActionButton = (val) => {
    API.Session.JoinMeeting({
      data: {
        role: "Cmsemployee",
        meetingId: val?.mettingname,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          navigate(
            `../${AdminRoute.Video.VideoCall}?id=${btoa(
              val.meetingtoken
            )}&name=${val.mettingname}`
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitAns = (val, option) => {
    if (!loadingSubmitAns) {
      setLoadingSubmitAns(true);
      const requestData = {
        event_id: val._id,
        created_by: UserData?.userdata?._id,
      };
      requestData[option] = true;
      API.Group.AnswerEvent({
        data: requestData,
      })
        .then((response) => {
          if (response.data.status === 1) {
            setLoadingSubmitAns(false);
            EventListFun();
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubmitAns(false);
        });
    }
  };

  useEffect(() => {
    // console.log("messageList", messageList);
  }, [messageList]);



  const groupName = sessionStorage.getItem('GroupName');
  const onBackClick = () => {
    navigate(-1);
  };


  console.log(messageList);


  return (
    <div className="app-container">
      <MobMenuBar></MobMenuBar>
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        {/* <Header isBack={true}></Header> */}
        <div className="app-header">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div onClick={() => onBackClick()}>
              <img
                className="cursor-pointer"
                src={backBtn}
                style={{
                  height: "30px",
                  marginLeft: "16px",
                  opacity: "0.7",
                }}
                alt="logo"
              />
            </div>
            <div className="groupName">
              {groupName}
            </div>
            <div className="d-flex">
              <img
                className="header-img cursor-pointer"
                onClick={() =>
                  navigate(`../${AdminRoute?.Auth?.Notifications}`)
                }
                alt="bell"
                style={{ height: "25px", marginLeft: "8px" }}
                src={bellIcon}
              />
            </div>
          </div>
        </div>
        <div className="chats-container">
          <div className="tabs-container">
            <div
              className="tab-item cursor-pointer"
              style={{
                borderBottom:
                  activeIndex === 0 ? "solid 3px rgb(249,221,163)" : "",
              }}
              onClick={() => setActiveIndex(0)}
            >
              Discussion
            </div>
            <div
              className="tab-item cursor-pointer"
              onClick={() => setActiveIndex(1)}
              style={{
                borderBottom:
                  activeIndex === 1 ? "solid 3px rgb(249,221,163)" : "",
              }}
            >
              Activity
            </div>
            <div
              className="tab-item cursor-pointer"
              onClick={() => setActiveIndex(2)}
              style={{
                borderBottom:
                  activeIndex === 2 ? "solid 3px rgb(249,221,163)" : "",
              }}
            >
              Resource
            </div>
          </div>
          <div className="chats-main">
            {activeIndex === 0 ? (
              <div className="scroller group-chatbox">
                <div className="scroller-content" id="scrollerContent">
                  {activeIndex === 0 &&
                    messageList?.map((messageContent, i) => {
                      return (
                        <div
                          key={messageContent?.author + i}
                          className={
                            UserData?.userdata?._id === messageContent?.user_id
                              ? "item my-3 send-msg"
                              : "item my-3 recive-msg"
                          }
                        >
                          <div
                            className={
                              UserData?.userdata?._id ===
                                messageContent?.user_id
                                ? "msg-card sent-mssg-card"
                                : "msg-card recived-msg-card"
                            }
                          >
                            <div className="mx-2 my-2 user-lbl d-flex align-items-center">
                              <p className="user-icon-avatar rounded-circle text-center mb-0 me-2 text-white">
                                {getInitialsLetters(
                                  messageContent?.author
                                    ? messageContent?.author
                                    : messageContent?.user[0]?.fullname?.split(
                                      " "
                                    )[0],
                                  messageContent?.author
                                    ? messageContent?.author
                                    : messageContent?.user[0]?.fullname?.split(
                                      " "
                                    )[1]
                                )}
                              </p>
                              {messageContent?.author
                                ? messageContent?.author
                                : messageContent?.user[0]?.fullname}
                              {messageContent.user[0].role === 'Wellnessclient' && (
                                <span className="badge">Wellness Coach</span>
                              )}
                            </div>
                            <div className="mx-2 my-2 msg-lbl">
                              {messageContent?.message}
                            </div>
                            <div className="d-flex justify-content-end time-lbl text-dark">
                              {/* {messageContent.time} */}
                              {moment(messageContent?.createdAt).format("ll")}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : activeIndex === 1 ? (
              <>
                <div className="scroller group-chatbox">
                  <div className="scroller-content" id="scrollerContent">
                    {/* Event  */}
                    {!EventList?.length ? (
                      // <h4 className="py-3">Data not found</h4>
                      <div className="alert alert-warning my-3" role="alert">
                        Data not found
                      </div>
                    ) : (
                      EventList?.length &&
                      EventList?.map((val, i) => {
                        const date =
                          val?.data?.startDate && val?.data?.startTime
                            ? combineDateTime(
                              val?.data?.startDate,
                              val?.data?.startTime
                            )
                            : new Date(); // shedual time zone
                        const currdate = new Date();
                        const beforeminute = date
                          ? new Date(get15MinutesBefore(date))
                          : new Date(); // get 15 min before time
                        const endDate = date && new Date(get1HourFuture(date));
                        const startDateResult = currdate > beforeminute; // true so sesstion btn show
                        const endDateResult = currdate < endDate; // true seesion btn show
                        // Provided time (15 minutes before the given date)
                        const providedTime = moment(
                          get15MinutesBefore(date)
                        ).toISOString();

                        // Given ISO 8601 date and time
                        const isoDate = val?.data?.startDate;
                        const isoTime = val?.data?.startTime;

                        // Combine the date and time
                        const combinedDateTime = `${isoDate?.split("T")[0]
                          } ${isoTime}`;

                        // Convert using moment.js
                        const formattedStartDateTime = moment(combinedDateTime);

                        // Given ISO 8601 date and time
                        const isoEndDate = val?.data?.endDate;
                        const isoEndTime = val?.data?.endTime;

                        // Combine the date and time
                        const combinedEndDateTime = `${isoEndDate?.split("T")[0]
                          } ${isoEndTime}`;

                        // Convert using moment.js
                        const formattedEndDateTime =
                          moment(combinedEndDateTime);

                        // Current time
                        const currentTime = moment();

                        // End time
                        const endTime = moment(endDate);

                        // Convert the ISO strings back to Moment objects for comparison
                        const currentTimeMoment = moment(currentTime);
                        const providedTimeMoment = moment(providedTime);
                        const endTimeMoment = moment(endTime);

                        // Check if the current time is between the provided time and end time
                        const isBetween = currentTimeMoment.isBetween(
                          formattedStartDateTime,
                          formattedEndDateTime,
                          null,
                          "[)" // Inclusive of start time
                        );
                        console.log(val.data.answer);

                        // const optionValue = val?.score;

                        const answersOption = {
                          option1: val.data.option1,
                          option2: val.data.option2,
                          option3: val.data.option3,
                        };

                        console.log('answersOption===>', answersOption);


                        // const selectedOption = Object.keys(optionValue).find(option => optionValue[option] === 1);
                        // console.log('selectedOption ==>', selectedOption);


                        // const correctOptionKey = Object.keys(answersOption).find(optionKey => answersOption[optionKey] === val?.data?.answer);
                        // console.log('correctOptionKey ==>', correctOptionKey);


                        // const isCorrect = selectedOption === correctOptionKey;


                        // let isSelected = selectedOption ? true : false;


                        // const classNameOption1 = isSelected
                        //   ? (isCorrect ? 'Quiz-bg' : 'fi-bg')  
                        //   : (isCorrect ? 'Quiz-bg' : '');      

                        // console.log('className ==>', classNameOption1);


                        return (
                          <>
                            <div key={i}>
                              {val?.data?.type === "Event" ? (
                                <>
                                  <div className="item my-3 send-msg">
                                    <div
                                      className="msg-card sent-mssg-card"
                                      style={{ background: "white" }}
                                    >
                                      <div className="mx-2 my-2 user-lbl">
                                        <img
                                          // src={userChatIcon}
                                          src={
                                            val?.data?.user[0]?.image
                                              ? val?.data?.user[0]?.image
                                              : userChatIcon
                                          }
                                          className="mx-1 user-chat-img"
                                          alt="chat icon"
                                        />
                                        {val?.data?.user?.length &&
                                          val?.data?.user[0]?.fullname}
                                      </div>
                                      {isBetween ? (
                                        <Button
                                          className="button_common m-0 px-3"
                                          onClick={() =>
                                            renderActionButton(val.data)
                                          }
                                        >
                                          Join Group Session
                                        </Button>
                                      ) : null}
                                      <div
                                        className="m-2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {val?.question}
                                      </div>
                                      <div className="mx-2 my-2 msg-lbl">
                                        {val?.description}
                                      </div>

                                      <>
                                        <div className="bacground-load">
                                          <div className="first-load">
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                Start Date :{" "}
                                                {moment(val?.startDate).format(
                                                  "l"
                                                )}
                                              </label>
                                              <label>
                                                End Date :{" "}
                                                {moment(val?.endDate).format(
                                                  "l"
                                                )}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load">
                                          <div className="first-load">
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                Start Time :{" "}
                                                {val?.data?.startTime}
                                              </label>
                                              <label>
                                                End Time: {val?.data?.endTime}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </>

                                      <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1">
                                        {/* <label>Here is the result so far</label> */}
                                        {/*
<label>11:00 Am</label>
*/}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : val?.data?.type?.toLowerCase() === "poll" ||
                                val.data.type?.toLowerCase() === "quiz" ? (
                                <>
                                  <div className="item my-3 send-msg">
                                    <div
                                      className="msg-card sent-mssg-card"
                                      style={{ background: "white" }}
                                    >
                                      <div className="mx-2 my-2 user-lbl">
                                        <img
                                          src={
                                            val?.data?.user[0]?.image
                                              ? val?.data?.user[0]?.image
                                              : userChatIcon
                                          }
                                          className="mx-1 user-chat-img"
                                          alt="chat icon"
                                        />
                                        {val?.data?.user?.length &&
                                          val?.data?.user[0]?.fullname}
                                      </div>
                                      <div
                                        className="m-2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {val?.data?.question}
                                      </div>
                                      {val?.isresSubmited ? (
                                        <>
                                          <div className="bacground-load">
                                            <div
                                              className={`first-load ${val?.data?.option1 === val.data.answer
                                                ? 'Quiz-bg' // Correct answer
                                                : val?.data?.option1 !== val.data.answer && val.score.option1 === 1
                                                  ? 'fi-bg'   // Selected wrong answer
                                                  : ''        // No class for unselected options
                                                }`} style={{ width: "100%" }}

                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option1 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option1}
                                                </label>
                                                {/* <label>
                                                  {parseInt(
                                                    (100 *
                                                      val?.score?.option1) /
                                                      val?.totaloption?.total
                                                  )}
                                                  %
                                                </label> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="bacground-load">
                                            <div
                                              className={`first-load ${val?.data?.option2 === val.data.answer
                                                ? 'Quiz-bg' // Correct answer
                                                : val?.data?.option2 !== val.data.answer && val.score.option2 === 1
                                                  ? 'fi-bg'   // Selected wrong answer
                                                  : ''        // No class for unselected options
                                                }`} style={{ width: "100%" }}
                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option2 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option2}
                                                </label>
                                                {/* <label>
                                                  {parseInt(
                                                    (100 *
                                                      val?.score?.option2) /
                                                      val?.totaloption?.total
                                                  )}
                                                  %
                                                </label> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="bacground-load">
                                            <div
                                              className={`first-load ${val?.data?.option3 === val.data.answer
                                                ? 'Quiz-bg' // Correct answer
                                                : val?.data?.option3 !== val.data.answer && val.score.option3 === 1
                                                  ? 'fi-bg'   // Selected wrong answer
                                                  : ''        // No class for unselected options
                                                }`} style={{ width: "100%" }}
                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option3 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option3}
                                                </label>
                                                {/* <label>
                                                  {parseInt(
                                                    (100 *
                                                      val?.score?.option3) /
                                                      val?.totaloption?.total
                                                  )}
                                                  %
                                                </label> */}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="bacground-load cursor_pointer"
                                            onClick={() =>
                                              handleSubmitAns(
                                                val.data,
                                                "option1"
                                              )
                                            }
                                          >
                                            <div
                                              className="first-load fi-bg "
                                            // style={{ width: "40%" }}
                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option1 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option1}
                                                </label>
                                                {/* <label>{`${
                                                  ((val?.score?.option1 || 0) /
                                                    (val?.totaloption?.total ||
                                                      1)) *
                                                  100
                                                }%`}</label> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="bacground-load cursor_pointer"
                                            onClick={() =>
                                              handleSubmitAns(
                                                val.data,
                                                "option2"
                                              )
                                            }
                                          >
                                            <div
                                              className="first-load fi-bg "
                                            // style={{ width: "30%" }}
                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option2 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option2}
                                                </label>
                                                {/* <label>{`${
                                                  ((val?.score?.option2 || 0) /
                                                    (val?.totaloption?.total ||
                                                      1)) *
                                                  100
                                                }%`}</label> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="bacground-load cursor_pointer"
                                            onClick={() =>
                                              handleSubmitAns(
                                                val.data,
                                                "option3"
                                              )
                                            }
                                          >
                                            <div
                                              className="first-load fi-bg "
                                            // style={{ width: "30%" }}
                                            // style={{
                                            //   width: `${
                                            //     ((val?.score?.option3 || 0) /
                                            //       (val?.totaloption?.total ||
                                            //         1)) *
                                            //     100
                                            //   }%`,
                                            // }}
                                            >
                                              <div className="d-flex justify-content-between final-fi">
                                                <label>
                                                  {val?.data?.option3}
                                                </label>
                                                {/* <label>{`${
                                                  ((val?.score?.option3 || 0) /
                                                    (val?.totaloption?.total ||
                                                      1)) *
                                                  100
                                                }%`}</label> */}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      {/* <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1">
                                        <label>
                                          {val?.isresSubmited &&
                                            val?.data?.type === "Quiz" &&
                                            "Answer"}
                                        </label>
                                        <label>
                                          {val?.isresSubmited &&
                                            val?.data?.answer}
                                        </label>
                                      </div> */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        );
                      })
                    )}

                    {/* sample card  */}

                    {/* <div className="item my-3 send-msg">
                      <div
                        className="msg-card sent-mssg-card"
                        style={{ background: "white" }}
                      >
                        <div className="mx-2 my-2 user-lbl">
                          <img
                            src={userChatIcon}
                            className="mx-1 user-chat-img"
                          />
                          Me
                        </div>
                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Which of the following method do you prefer ?
                        </div>
                        <div className="mx-2 my-2 msg-lbl">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Omnis dolores explicabo iure repudiandae, quo
                          repellat. Reiciendis facere maxime in commodi
                          voluptatibus quos hic optio, culpa magni, a, dolorem
                          recusandae cumque.
                        </div>

                        {!showRes ? (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{ width: "70%", background: "white" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{
                                  width: "30%",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "70%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                  <label>70%</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "30%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                  <label>30%</label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="d-flex justify-content-between time-lbl ">
                          <label>Here is the result so far</label>
                          <label>11:00 Am</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ) : activeIndex === 2 ? (
              <>
                <div className="scroller group-chatbox">
                  {!MediaList?.length ? (
                    <div className="alert alert-warning my-3" role="alert">
                      Data not found
                    </div>
                  ) : (
                    MediaList?.length &&
                    MediaList?.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="scroller-content"
                          id="scrollerContent"
                        >
                          <div className="item my-3 send-msg">
                            {val?.type === "pdf" ? (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <a
                                  href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="file-div justify-content-evenly">
                                    <img
                                      src={pdfIcon}
                                      style={{ height: "38px" }}
                                      alt="pdf icon"
                                    />
                                    <p
                                      style={{
                                        maxWidth: "100px",
                                        wordWrap: "break-word",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {val?.originalfilename}
                                    </p>
                                  </div>
                                  <p
                                    className="my-1 mt-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {val?.originalfilename}
                                  </p>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Media Type: Blog
                                  </p>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {/* Today, 12:00PM */}
                                    {moment(val?.createdAt).format("ll")}
                                  </p>
                                </a>
                              </div>
                            ) : (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <a
                                  href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="file-div justify-content-evenly">
                                    <img
                                      src={`${baseApi?.baseurl}images/${val?.mediafile}`}
                                      style={{
                                        maxHeight: "75px",
                                        maxWidth: "125px",
                                      }}
                                      alt="media"
                                    />
                                    {/* {val?.originalfilename} */}
                                  </div>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {val?.originalfilename}
                                    <br></br>
                                    {/* Today, 12:00PM */}
                                    {moment(val?.createdAt).format("ll")}
                                  </p>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {activeIndex === 0 && (
              <div className="chat-input-box chat-input-box-client justify-content-evenly cursor-pointer mb-0">
                <input
                  className="chat-input-text"
                  type="text"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  cols={50}
                  value={currMessage}
                  onChange={(event) => setCurrMessage(event.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <button className="send-btn" onClick={() => sendMessage()}>
                  <img src={sendIcon} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
