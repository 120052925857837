import BaseApi from "./BaseApi";

const API = {
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/resendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    LoginAPIMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/mresendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/create`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpphon`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpmail`,
        data: props.data,
        tokenRequired: true,
      });
    },
    TherapistCalenderUpdate: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/theropistcalender/${props.UserId}`,
        data: props.data,
        tokenRequired: false,
      });
    },
    SendmsOtpEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/sendcmsotpemail`,
        data: props.data,
        tokenRequired: true,
      });
    },
    WellnessCoachlisting: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/theropist?key=Wellnessclient&page=1&size=30`,
        tokenRequired: true,
      });
    },
    TherpistAdd: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/theropistAdd`,
        data: props.data,
        tokenRequired: true,
      });
    },
    UpdateUser: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/survayfill`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Profile: {
    GetProfileData: () => {
      return BaseApi({
        method: "GET",
        url: `users/userinfo`,
        tokenRequired: true,
      });
    },
    UpdateProfileAPI: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyPlanlfeatures: () => {
      return BaseApi({
        method: "GET",
        url: `/plan/planlfeatures`,
        tokenRequired: true,
      });
    },
  },
  Notes: {
    CreateNotes: (props) => {
      return BaseApi({
        method: "POST",
        url: `notes/createnotes`, //geeta
        data: props.data,
        tokenRequired: true,
      });
    },

    NotesList: () => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist`, //geeta
        tokenRequired: true,
      });
    },
    NotesDetailbyId: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.notes_id}`, //geeta
        data: props.data,
        tokenRequired: true,
      });
    },

    UpdateNote: (props) => {
      return BaseApi({
        method: "PUT",
        url: `notes/updatenotes/${props.NoteId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Group: {
    CreateGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `groups/creategroup`,
        data: props.data,
        tokenRequired: true,
      });
    },

    GroupList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsGroups/grouplistall?key=${props.data.type}&name=${props.data.serchKey ? props.data.serchKey : ""
          }&ingroup=${props.data.inGroup ? "yes" : ""}`,
        tokenRequired: true,
      });
    },
    GroupListIN: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsGroups/cmsGrouplist?key=${props.data.type}&name=${props.data.serchKey ? props.data.serchKey : ""
          }&ingroup=${props.data.inGroup ? "yes" : ""}`,
        tokenRequired: true,
      });
    },
    JoinGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `/cmsGroups/joinCmsgroup`,
        data: props.data,
        tokenRequired: true,
      });
    },

    JoinGroupsList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsGroups/cmsJoinGrouplist`,
        data: props.data,
        tokenRequired: true,
      });
    },

    ClientListing: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/list?page=1&role=Client`,
        tokenRequired: false,
      });
    },
    TherapistListing: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/list?page=1&role=Therapist`,
        tokenRequired: false,
      });
    },
    UpdateGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/updategroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupsChatList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/chat/chatlist/${props.group_id}`,
        tokenRequired: true,
      });
    },

    SelectGroupMembers: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/joingroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    AnswerEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `/event/answerevent`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Event: {
    CreateEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `event/createevent`,
        data: props.data,
        tokenRequired: true,
      });
    },

    EventList: (props) => {
      return BaseApi({
        method: "GET",
        url: `event/cmseventlist?key=${props.GroupId}`,
        tokenRequired: true,
      });
    },

    MediaList: (props) => {
      return BaseApi({
        method: "GET",
        url: `groupmedia/medialist?key=${props.GroupId}`,
        tokenRequired: true,
      });
    },
    CreateMedia: (props) => {
      return BaseApi({
        method: "POST",
        url: `groupmedia/createmedia`,
        data: props.data,
        tokenRequired: false,
      });
    },
  },
  Client: {
    ClientActiveList: () => {
      return BaseApi({
        method: "GET",
        url: `/notes/theropistclientlistactive`,
        tokenRequired: true,
      });
    },
    ClientInActiveList: () => {
      return BaseApi({
        method: "GET",
        url: `/notes/theropistclientlistinactive`,
        tokenRequired: true,
      });
    },

    Digital_Notes: (props) => {
      return BaseApi({
        method: "GET",
        url: `/notes/usernotelist?id=${props.UserId}`,
        tokenRequired: true,
      });
    },
    SessionDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/cmsSession/cmsSessiondetails/${props.SessionId}`,
        tokenRequired: true,
      });
    },
  },
  Refer: {
    CreateRefer: (props) => {
      return BaseApi({
        method: "POST",
        url: `/referrel/createrefer`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Therapist: {
    CreatMessageid: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/creatmessageid`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Chatapi: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/chatapi`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChatHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: props.msgId
          ? `/aiconnect/aichatlist?messageid=${props.msgId}`
          : `/aiconnect/aichatlist`,
        tokenRequired: true,
      });
    },

    Aichatlisthistory: () => {
      return BaseApi({
        method: "GET",
        url: `/aiconnect/aichatlisthistory`,
        tokenRequired: true,
      });
    },
    getTherpistDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/cmstheropistdtls?date=${props.date}`,
        tokenRequired: true,
      });
    },
  },
  Session: {
    CreateSession: (props) => {
      return BaseApi({
        method: "POST",
        url: `/cmsSession/createCmssession`,
        data: props.data,
        tokenRequired: true,
      });
    },
    upcomingSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/cmsSession/upCmssessionlist`,
        tokenRequired: true,
      });
    },
    sumarySessionList: () => {
      return BaseApi({
        method: "GET",
        url: `cmsSession/cmsSumarysessionlist`,
        tokenRequired: true,
      });
    },
    currentSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `/session/currentsessionlist`,
        tokenRequired: true,
      });
    },
    DeleteSession: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/flushSessioncms?id=${props.id}`,
        tokenRequired: true,
      });
    },
    CurrentSessionList: () => {
      return BaseApi({
        method: "GET",
        url: `cmsSession/currentCmssessionlist`,
        tokenRequired: true,
      });
    },
    JoinMeeting: (props) => {
      return BaseApi({
        method: "POST",
        data: props.data,
        url: `/session/joinmeeting`,
        tokenRequired: true,
      });
    },
  },
  Survey: {
    Surveylist: (props) => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/survaylist?title=${props.title}`,
        tokenRequired: true,
      });
    },
    AnswerEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `/event/answerevent`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SubmitSurvay: (props) => {
      return BaseApi({
        method: "POST",
        url: `/survaytype/answersurvey`,
        data: props.data,
        tokenRequired: true,
      });
    },
    AnswerSurvey: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/answersurvey`,
        tokenRequired: true,
      });
    },
    Wellness: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/welness`,
        data: props.data,
        tokenRequired: true,
      });
    },
    WellnessTagsList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/welnessTags`,
        tokenRequired: true,
      });
    },
    InsertIndividualWellnessTags: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/insertIndividualWellnessTags`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getIndividualWellnessTags: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/getIndividualWellnessTags`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MoodTrackerStatus: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/moodstatus`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GetTimit: () => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/getlimit`,
        tokenRequired: true,
      });
    },
  },
  Plaground: {
    Mental_HealthAPI: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.health}`,
        tokenRequired: false,
      });
    },
    Videos_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.kind}`,
        tokenRequired: false,
      });
    },

    // Self Assessment
    SelfAsses_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.selfAsses}`,
        tokenRequired: false,
      });
    },
  },
  MyThreapist: {
    meadialistindividual: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groupmedia/medialistindividual?id=${props.Userid}`,
        tokenRequired: true,
      });
    },
    ChatList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/messages`,
        tokenRequired: true,
      });
    },
  },
  NeedAnalysisFrom: {
    NeedAnalysisSurvay: () => {
      return BaseApi({
        method: "GET",
        url: `survaytype/needanalysis?type=needanalysis`,
        tokenRequired: true,
      });
    },
    NeedAnalysisFromAnswer: (props) => {
      return BaseApi({
        method: "POST",
        url: `/survaytype/anseranalysis`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Leadership: {
    LeadershipSurvay: () => {
      return BaseApi({
        method: "GET",
        url: `survaytype/needanalysis?type=leadership`,
        tokenRequired: true,
      });
    },
    LeadershipFromAnswer: (props) => {
      return BaseApi({
        method: "POST",
        url: `/survaytype/anseranalysis`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
  ResheduleLimit: {
    LimitSessionReshedule: (props) => {
      return BaseApi({
        method: "POST",
        url: `/reshedule/resheduleLog`, // tbd
        data: props.data,
        tokenRequired: true,
      });
    },
    getResheduledData: (props) => {
      return BaseApi({
        method: "POST",
        url: `/reshedule/getresheduleLog`, // tbd
        data: props.data,
        tokenRequired: true,
      });
    },
  },
};

export default API;
