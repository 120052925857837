import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Make sure to import video.js styles
import 'videojs-youtube'; // Import the YouTube plugin
import VideoJS from '../Pages/VideoJS';

export default function VideoPlayer({ url, poster }) {
    const playerRef = React.useRef(null);
    const videoJsOptions = {
        autoplay: false,
        muted: true,
        controls: true,
        responsive: true,
        fluid: true,
        loop: false,
        sources: [{
            src: url,
            type: 'video/youtube' // Use the YouTube type
        }],
        poster: poster,
        controlBar: {
            pictureInPictureToggle: false 
        }
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    return (
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    );
}
