import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import profileImg from "../../assets/images/icons/Icon awesome-user-circle.png";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import Setting from "../../assets/images/icons/Path 15092.png";
import bellIcon from "../../assets/images/icons/Icon ionic-ios-notifications-outline.png";

const Header = (props) => {
  // console.log(props.isBack);
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate(-1);
  };
  const [UserInfo, setUserInfo] = useState([]);

  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          setUserInfo(response?.data?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  
  return (
    <div className="app-header">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <div>
          {" "}
          <>
            {props.isBack ? (
              <span onClick={() => onBackClick()}>
                <img
                  className="cursor-pointer"
                  src={backBtn}
                  style={{
                    height: "30px",
                    marginLeft: "16px",
                    opacity: "0.7",
                  }}
                  alt="logo"
                />
              </span>
            ) : (
              <img
                onClick={() => navigate(`../${AdminRoute?.Auth?.Profile}`)}
                className="form-upload-photo-preview3  cursor-pointer"
                style={{ height: "30px" }}
                src={UserInfo?.image ? UserInfo?.image : profileImg}
                alt="logo"
              />
            )}
          </>
        </div>
        <div>
          {props.header ? (
            <>
              <label className="header-labl secheading">{props.header}</label>
            </>
          ) : (
            <>
              <img style={{ height: "30px" }} src={rtylifeLogo} alt="rytlogo" />
           
            </>
          )}
        </div>
        <div className="d-flex">
          {props.isSetting ? (
            <>
              <img
                onClick={() => navigate(`../${AdminRoute?.Auth?.Settings}`)}
                className="header-img setting_icon  cursor-pointer"
                style={{ height: "26px", width: "26px" }}
                src={Setting}
                alt="logo"
              />
            </>
          ) : (
            <>
              {/* <img
                className="header-img cursor-pointer"
                src={activityListIcon}
                onClick={() =>
                  navigate(`../${AdminRoute?.Auth?.MyActivityList}`)
                }
              /> */}
              <div className="position-relative">
                {/*
<p className="noti-count position-absolute bg-danger rounded-circle text-center"></p>
*/}
                <img
                  className="header-img cursor-pointer"
                  onClick={() =>
                    navigate(`../${AdminRoute?.Auth?.Notifications}`)
                  }
                  alt="bell"
                  style={{ height: "25px", marginLeft: "8px" }}
                  src={bellIcon}
                />
              </div>
            </>
          )}
          {props.isSkipBtn ? (
            <button
              className="skip-btn ms-3"
              onClick={() => navigate(`${AdminRoute?.Auth?.NeedAnalysisForm}`)}
            >
              Skip
            </button>
          ) : (
            <></>
          )}
        </div>

      </div>
    </div>
  );
};

export default Header;
