import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import groupIcon2 from "../../assets/images/icons/Group 21756.png";
import aiTherapist from "../../assets/images/dashboard/ai-therapist.jpg";
import groupImg from "../../assets/images/dashboard/groups.jpg";
import playgroundImg from "../../assets/images/dashboard/playground.jpg";
import AdminRoute from "../../Route/RouteDetails";
import { NavLink, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import moment from "moment";
import {
  get1HourFuture,
  combineDateTime,
  get15MinutesBefore,
} from "../Common/Function";
import { toast } from "react-toastify";
import Mood5 from "../../assets/images/moodTracker/1.png";
import Mood4 from "../../assets/images/moodTracker/2.png";
import Mood3 from "../../assets/images/moodTracker/3.png";
import Mood2 from "../../assets/images/moodTracker/4.png";
import Mood1 from "../../assets/images/moodTracker/5.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [UpcommingList, setUpcommingList] = useState([]);
  const [ClientName, setClientName] = useState("");
  const [ActivityCount, setActivityCount] = useState(0);
  const [MessagecntCount, setMessagesCount] = useState(0);

  // const [timeZone, setTimeZone] = useState('');

  // const [moodTraker, setMoodTraker] = useState({
  //   Joy: "1",
  //   drive: "1",
  //   stress: "1",
  // });
  // const [lifeStyleTraker, setLifeStyleTraker] = useState({
  //   fodIntake: "50",
  //   sleep: "50",
  //   activity: "50",
  // });
  const [happyIndicator, setHappyIndicator] = useState(1);
  const [motivationIndicator, setMotivationIndicator] = useState(1);
  const [stressIndicator, setStressIndicator] = useState(1);

  const [showHappy, setShowHappy] = useState(true);
  const [showMotivation, setShowMotivation] = useState(true);
  const [showStress, setShowStress] = useState(true);

  const [moodEmojiHappy, setMoodEmojiHappy] = useState(null);
  const [moodEmojiMotivation, setMoodEmojiMotivation] = useState(null);
  const [moodEmojiStress, setMoodEmojiStress] = useState(null);
  const [visibleItems, setVisibleItems] = useState([]);

  const [wellnessTagsList, setWellnessTagsList] = useState([]);

  const [CurrentSession, setCurrentSession] = useState({});
  const [joinSessionBtn, setJoinSessionBtn] = useState(false);
  const date =
    CurrentSession?.date && CurrentSession?.timestart
      ? combineDateTime("2024-05-13T01:00:00.000Z", "01:00 AM")
      : new Date(); // shedual time zone
  // console.log(CurrentSession.date);
  // const tempDate = '2024-02-25T01:30:00.000Z'
  const currdate = new Date(); //current time

  const beforeminute = date
    ? new Date(get15MinutesBefore("2024-05-13T01:00:00.000"))
    : new Date(); // get 15 min before time
  // console.log(currdate > beforeminute) // true so sesstion btn show

  const endDate = date && new Date(get1HourFuture(date));
  // const startDateResutl = (currdate > beforeminute) // true show btn
  // console.log(currentSession)

  const startDateResult = currdate > beforeminute; // true so sesstion btn show
  const endDateResult = currdate < endDate; // true seesion btn show

  const [showTagSelection, setShowTagSelection] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const moodItems = [
    { src: moodEmojiHappy, text: 'Happy' },
    { src: moodEmojiMotivation, text: 'Motivation' },
    { src: moodEmojiStress, text: 'Stress' },
  ];


  const delayTimer = () => {
    const timeouts = [];
    moodItems.forEach((_, index) => {
      const timeoutId = setTimeout(() => {
        setVisibleItems((prev) => [...prev, index]);
      }, index * 500 + 100);
      timeouts.push(timeoutId);
    });

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getMoodTrackerValues = () => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(); // Get the current date
    const formattedDate = formatDate(date);
    // setTimeZone(clientTimeZone);
    try {
      API.Survey.MoodTrackerStatus({
        data: {
          clientId: UserData?.userdata?._id,
          clientTimeZone: clientTimeZone,
          clientDate: formattedDate
        },
      })
        .then((response) => {
          if (response.data.status === 1) {
            const checkStatus = response.data.data;
            if (checkStatus.length > 0) {
              checkStatus.forEach(entry => {
                if (entry.moodType === 'happinessIndicator') {
                  setShowHappy(false);
                  setHappyIndicator(entry.moodIndicatorValue);
                }
                if (entry.moodType === 'stressIndicator') {
                  setShowStress(false);
                  setStressIndicator(entry.moodIndicatorValue);
                }
                if (entry.moodType === 'motivationIndicator') {
                  setShowMotivation(false);
                  setMotivationIndicator(entry.moodIndicatorValue);
                }

              });
            }
          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    switch (happyIndicator) {
      case 1:
        setMoodEmojiHappy(Mood1); // Mood 1 emoji
        break;
      case 2:
        setMoodEmojiHappy(Mood2); // Mood 2 emoji
        break;
      case 3:
        setMoodEmojiHappy(Mood3); // Mood 3 emoji
        break;
      case 4:
        setMoodEmojiHappy(Mood4); // Mood 4 emoji
        break;
      case 5:
        setMoodEmojiHappy(Mood5); // Mood 5 emoji
        break;
      default:
        setMoodEmojiHappy(Mood3); // Neutral emoji for unexpected values
    }
  }, [happyIndicator])

  useEffect(() => {
    switch (motivationIndicator) {
      case 1:
        setMoodEmojiMotivation(Mood1); // Mood 1 emoji
        break;
      case 2:
        setMoodEmojiMotivation(Mood2); // Mood 2 emoji
        break;
      case 3:
        setMoodEmojiMotivation(Mood3); // Mood 3 emoji
        break;
      case 4:
        setMoodEmojiMotivation(Mood4); // Mood 4 emoji
        break;
      case 5:
        setMoodEmojiMotivation(Mood5); // Mood 5 emoji
        break;
      default:
        setMoodEmojiMotivation(Mood3); // Neutral emoji for unexpected values
    }
  }, [motivationIndicator])


  useEffect(() => {
    switch (stressIndicator) {
      case 1:
        setMoodEmojiStress(Mood5); // Mood 1 emoji
        break;
      case 2:
        setMoodEmojiStress(Mood4); // Mood 2 emoji
        break;
      case 3:
        setMoodEmojiStress(Mood3); // Mood 3 emoji
        break;
      case 4:
        setMoodEmojiStress(Mood2); // Mood 4 emoji
        break;
      case 5:
        setMoodEmojiStress(Mood1); // Mood 5 emoji
        break;
      default:
        setMoodEmojiStress(Mood3); // Neutral emoji for unexpected values
    }
  }, [stressIndicator])


  const upcomingListfun = () => {
    try {
      API.Client.UpcomingListdashboard({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
      }).then((response) => {
        if (response.data.status === 1) {
          setUpcommingList(response?.data?.data_all[0]);
          setClientName(
            `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };



  const getWellnessTags = () => {
    try {
      API.Survey.WellnessTagsList()
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            const checkStatus = response.data.data_all;
            if (checkStatus.length > 0) {
              setWellnessTagsList(checkStatus);
            }
          }
        })
    } catch (error) {
      console.log(error);
    }
  }




  useEffect(() => {
    upcomingListfun();
    MyPlanlfeatures();
    getCurrentSession();
    getMoodTrackerValues();
    getWellnessTags();
    getSelectedWellnessTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentSession = () => {
    API.Session.CurrentSessionList()
      .then((response) => {
        if (response.data.status === 1) {
          // console.log(response);
          setCurrentSession(response.data.data_all[0]);
          // setActivityCount(response.data.activitycount);
          // setMessagesCount(response.data.messagecntcount);
          // console.log(response.data.activitycount);
          // console.log(
          //   get15MinutesBefore(response.data.data_all[0].date.slice(0, -1))
          // );

          // Provided time
          const providedTime = moment(
            get15MinutesBefore(response.data.data_all[0].date.slice(0, -1))
          );

          // Current time
          const currentTime = moment();
          const endTime = moment(response.data.data_all[0].timeend);
          // Subtract 15 minutes from the current time
          const fifteenMinutesBefore = currentTime
            .clone()
            .subtract(15, "minutes");
          // Check if provided time is within 15 minutes before current time
          if (currentTime.isBetween(providedTime, endTime)) {
            // console.log(
            //   "Provided time is within 15 minutes before the current time."
            // );
            setJoinSessionBtn(true);
          } else {
            // console.log(
            //   "Provided time is NOT within 15 minutes before the current time."
            // );
            setJoinSessionBtn(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MyPlanlfeatures = () => {
    try {
      API.Profile.MyPlanlfeatures().then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem('OrganisationPlanId', response.data.plandetails.plan_id)
          if (response.data.welnessdata.length) {
            // setMoodTraker({
            //   ...moodTraker,
            //   drive: response.data.welnessdata[0].drive,
            //   Joy: response.data.welnessdata[0].joy,
            //   stress: response.data.welnessdata[0].stress,
            // });
            // setLifeStyleTraker({
            //   ...lifeStyleTraker,
            //   fodIntake: response.data.welnessdata[0].foodIntake,
            //   sleep: response.data.welnessdata[0].sleep,
            //   activity: response.data.welnessdata[0].physicalactivity,
            // });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlewellness = (val, value) => {
    API.Survey.Wellness({
      data: {
        type: val,
        value: value,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderActionButton = () => {
    API.Session.JoinMeeting({
      data: {
        role: "Cmsemployee",
        meetingId: CurrentSession?.mettingname,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          navigate(
            `../${AdminRoute.Video.VideoCall}?id=${btoa(
              CurrentSession.meetingtoken
            )}&name=${CurrentSession.mettingname}`
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const insertUserWellnessTag = (id, name) => {
    API.Survey.InsertIndividualWellnessTags({
      data: {
        tagId: id,
        tagName: name,
        userId: UserData?.userdata?._id,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          getSelectedWellnessTags();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getSelectedWellnessTags = () => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(); // Get the current date
    const formattedDate = formatDate(date);
    // setTimeZone(clientTimeZone);
    try {
      API.Survey.getIndividualWellnessTags({
        data: {
          clientId: UserData?.userdata?._id,
          clientTimeZone: clientTimeZone,
          clientDate: formattedDate
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            const checkStatus = response.data.data;

            if (checkStatus.length < 3) {
              setShowTagSelection(true);
            }else{
              setShowTagSelection(false);
            }
            setSelectedTags(checkStatus);

          }
        })
    } catch (error) {
      console.log(error);
    }
  }





  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 160px)" }}
        >
          <div className="row px-3 py-2">
            <div className="sub-head px-3 px-md-0 col-12">
              <p className="sub-heading">
                Welcome {UserData?.userdata?.fullname}!
              </p>
            </div>
          </div>
          {/* ===========Wellness Tracker============ */}

          <div className="row px-3 px-md-0">
            <div className="col-lg-6 mb-3 col-md-12 list-item">
              <div className="dashboard-inner p-3">
                <div className="row">
                  <div className="dashboard-text-div">
                    <h3 className="secheading mt-2">Mood Tracker</h3>
                    {!showHappy && !showMotivation && !showStress ? (
                      <div className="showClientMoodEmoji" onLoad={() => delayTimer()}>
                        {moodItems.map((item, index) => (
                          <div key={index} className={`moodItem ${visibleItems.includes(index) ? 'visible' : ''}`}>
                            <img src={item.src} alt="Mood emoji" />
                            <p className="moodText">{item.text}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <p className="mb-3">How are you feeling today?</p>
                        <div className="row">
                          <div className="col-md-12 mb-2 wellness-tracker-border border-end ">
                            {/* <h5 className="mb-3">Mood Tracker</h5> */}
                            <h4 className="mb-0 dm-serif-font">Happy</h4>
                            <div className="col-12 wellness-tracker-slider mb-3 pb-4">
                              <div className="row px-3">
                                <div className="col-12 slider-input position-relative px-0">
                                  <input
                                    type="range"
                                    className="form-range text-black"
                                    id="typeinp"
                                    min="1"
                                    max="5"
                                    name="answer_score"
                                    value={happyIndicator}
                                    disabled={!showHappy}
                                    onChange={(e) => [
                                      setHappyIndicator(
                                        e.target.value,
                                      ),
                                      handlewellness("happinessIndicator", e.target.value),
                                      setShowHappy(false),
                                      getMoodTrackerValues()
                                    ]}
                                    style={{
                                      background: !showHappy
                                        ? 'linear-gradient(to right, grey 0%, grey 100%)' // Greyscale when disabled
                                        : 'linear-gradient(to right, red 0%,blue 25%, yellow 50%,orange 75%, green 100%)', // Original gradient
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                            <p className=" w-18 text-start progress-bar_text my-0">
                              Low
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Moderate
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              High
                            </p>
                          </div> */}
                            </div>

                            <h4 className="mb-0 dm-serif-font">Motivaton</h4>
                            <div className="col-12 wellness-tracker-slider mb-3 pb-4">
                              <div className="row px-3">
                                <div className="col-12 slider-input position-relative px-0">
                                  <input
                                    type="range"
                                    className="form-range text-black"
                                    id="typeinp"
                                    min="1"
                                    max="5"
                                    name="answer_score"
                                    value={motivationIndicator}
                                    disabled={!showMotivation}
                                    onChange={(e) => [
                                      setMotivationIndicator(
                                        e.target.value,
                                      ),
                                      handlewellness("motivationIndicator", e.target.value),
                                      setShowMotivation(false),
                                      getMoodTrackerValues()
                                    ]}
                                    style={{
                                      background: !showMotivation
                                        ? 'linear-gradient(to right, grey 0%, grey 100%)' // Greyscale when disabled
                                        : 'linear-gradient(to right, red 0%, yellow 50%, green 100%)', // Original gradient
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                            <p className=" w-18 text-start progress-bar_text my-0">
                              Low
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Moderate
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              High
                            </p>
                          </div> */}
                            </div>

                            <h4 className="mb-0 dm-serif-font">Stress</h4>
                            <div className="col-12 wellness-tracker-slider">
                              <div className="row px-3">
                                <div className="col-12 slider-input position-relative px-0">
                                  <input
                                    type="range"
                                    className="form-range text-black"
                                    id="typeinp"
                                    min="1"
                                    max="5"
                                    name="answer_score"
                                    value={stressIndicator}
                                    disabled={!showStress}
                                    onChange={(e) => [
                                      setStressIndicator(
                                        e.target.value,
                                      ),
                                      handlewellness("stressIndicator", e.target.value),
                                      setShowStress(false),
                                      getMoodTrackerValues()
                                    ]}
                                    style={{
                                      background: !showStress
                                        ? 'linear-gradient(to right, grey 0%, grey 100%)' // Greyscale when disabled
                                        : 'linear-gradient(to left, red 0%, yellow 50%, green 100%)', // Original gradient
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6 mb-2">
                        <h5 className="mb-3">Lifestyle Tracker</h5>
                        <h4 className="mb-0 dm-serif-font">Food Intake</h4>
                        <div className="col-12 wellness-tracker-slider mb-3">
                          <div className="row px-3">
                            <div className="col-12 slider-input position-relative px-0">
                              <input
                                type="range"
                                className="form-range text-black"
                                id="typeinp"
                                min="0"
                                max="100"
                                name="answer_score"
                                value={lifeStyleTraker.fodIntake}
                                onChange={(e) => [
                                  setLifeStyleTraker({
                                    ...lifeStyleTraker,
                                    fodIntake: e.target.value,
                                  }),
                                  handlewellness("fodIntake", e.target.value),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                            <p className=" w-18 text-start progress-bar_text my-0">
                              Poor
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Adequate
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Excessive
                            </p>
                          </div>
                        </div>

                        <h4 className="mb-0 dm-serif-font">Sleep</h4>
                        <div className="col-12 wellness-tracker-slider mb-3">
                          <div className="row px-3">
                            <div className="col-12 slider-input position-relative px-0">
                              <input
                                type="range"
                                className="form-range text-black"
                                id="typeinp"
                                min="0"
                                max="100"
                                name="answer_score"
                                value={lifeStyleTraker.sleep}
                                onChange={(e) => [
                                  setLifeStyleTraker({
                                    ...lifeStyleTraker,
                                    sleep: e.target.value,
                                  }),
                                  handlewellness("sleep", e.target.value),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                            <p className=" w-18 text-start progress-bar_text my-0">
                              Poor
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Adequate
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Excessive
                            </p>
                          </div>
                        </div>

                        <h4 className="mb-0 dm-serif-font">Activity</h4>
                        <div className="col-12 wellness-tracker-slider">
                          <div className="row px-3">
                            <div className="col-12 slider-input position-relative px-0">
                              <input
                                type="range"
                                className="form-range text-black"
                                id="typeinp"
                                min="0"
                                max="100"
                                name="answer_score"
                                value={lifeStyleTraker.activity}
                                onChange={(e) => [
                                  setLifeStyleTraker({
                                    ...lifeStyleTraker,
                                    activity: e.target.value,
                                  }),
                                  handlewellness("activity", e.target.value),
                                ]}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between w-100 wellness-tracker-text">
                            <p className=" w-18 text-start progress-bar_text my-0">
                              Poor
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Adequate
                            </p>
                            <p className=" w-17 text-center progress-bar_text my-0">
                              Excessive
                            </p>
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </>
                    )}
                    {/* <h3 className="secheading mt-2">Pick Other Suitable Moods (upto 3)</h3> */}
                    <p className="mb-3">Tell us more about how you are feeling today?</p>
                    {showTagSelection && showTagSelection ? (
                      <div className="badge-container">
                        {wellnessTagsList && wellnessTagsList.length > 0 ? (
                          wellnessTagsList.map((record, index) => (
                            <div key={index} className="badge" onClick={() => insertUserWellnessTag(record.id, record.tagName)}>+{' '}{record.tagName}</div>
                          ))
                        ) : (
                          <div>No Wellness Tags to display</div>
                        )}
                      </div>
                    ) : (
                      <div className="badge-container">
                        {selectedTags && selectedTags.length > 0 ? (
                          selectedTags.map((record, index) => (
                            <div key={index} className="badge">{record.tagName}</div>
                          ))
                        ) : (
                          <div>No Wellness Tags to display</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* =================Upcoming Session=============== */}
            <div className="col-lg-3 mb-3 col-md-12 list-item">
              <div className="dashboard-inner p-3 h-100">
                <div className="row h-100">
                  <div className="col-md-12 mb-2">
                    <div className="time-text-right">
                      <div className="dashboard-text-div">
                        <h3 className="secheading mt-2 mb-2">
                          Upcoming Session
                        </h3>
                        {CurrentSession?.date ? (
                          <div className="display-session">
                            <p className="my-2">
                              Attend all sessions for speedier recovery.
                            </p>
                            <div className="upcoming-session-box d-flex justify-content-evenly align-items-center px-3 py-2 mb-4">
                              <div className="date-month">
                                <p className="mb-0">
                                  {CurrentSession &&
                                    CurrentSession?.date
                                      ?.split("T")[0]
                                      ?.split("-")[2]}
                                </p>
                                <p className="fw-bold mb-0">
                                  {CurrentSession?.month}
                                </p>
                              </div>
                              <div className="day-time">
                                <p className="mt-0 mb-0">
                                  {CurrentSession?.day}
                                </p>
                                <p className="mb-0 fw-bold">
                                  {CurrentSession?.timestart}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              {/* {CurrentSession?._id &&
                                CurrentSession?.timeend
                                &&
                                // (new Date(CurrentSession.timeend) - new Date() >= 2 * 60 * 60 * 1000) &&
                                (
                                  <p
                                    className="me-2 mb-0 reschedule text-decoration-underline cursor-pointer"
                                    // onClick={() =>
                                    //   navigate(
                                    //     `../${AdminRoute.Auth.MyCalender}/?id=${CurrentSession?._id}`
                                    //   )
                                    // }
                                    onClick={handleRescheduleClick}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      className="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>
                                    <span className="ms-1">Reschedule</span>
                                  </p>
                                )} */}

                              {CurrentSession?.date && joinSessionBtn ? (
                                <Button
                                  className="button_common m-0 px-3"
                                  onClick={() => renderActionButton()}
                                >
                                  Join Session
                                </Button>
                              ) : (
                                <Button className="button_common m-0 float-end">
                                  <NavLink
                                    to={`../${AdminRoute?.Auth?.MyCalender}/?key=true`}
                                    className={"text-decoration-none text-dark"}
                                  >
                                    Book a Session
                                  </NavLink>
                                </Button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="hide-session">
                            <p className="my-2">No Sessions Available.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =================Updates From Therapist=============== */}
            <div className="col-lg-3 mb-2 col-md-12 list-item">
              <div className="dashboard-inner p-3 h-100">
                <div className="row h-100">
                  <div className="col-md-12">
                    <div className="time-text-right">
                      <div className="dashboard-text-div">
                        <h3 className="secheading mt-2 mb-2">
                          Updates From Therapist
                        </h3>
                        {CurrentSession?.theropis?.length ? (
                          <div className="display-updates">
                            <p className="my-2">
                              Attend all sessions for speedier recovery.
                            </p>
                            <div className="update-therapist-box d-flex justify-content-center align-items-center py-2 mb-1 pt-0">
                              <div className="name-profile">
                                <p className="text-center mx-auto">
                                  <img src={groupIcon2} alt="PlayGround" />
                                </p>
                                <p className="fw-bold text-center px-4">
                                  {CurrentSession?.theropis?.length &&
                                    CurrentSession?.theropis[0]?.fullname}
                                </p>
                              </div>
                              <div className="activity-messages">
                                {/* <p className="mt-0 fw-bold">
                                  <span>
                                    {ActivityCount ? ActivityCount : "0"}
                                  </span>
                                  Activity
                                </p>
                                <p className="mb-0 fw-bold">
                                  <span>
                                    {MessagecntCount ? MessagecntCount : "0"}
                                  </span>
                                  Messages
                                </p> */}
                              </div>
                            </div>
                            <div className="update-therapist-box d-flex justify-content-center align-items-center py-4 mb-1 pt-0">
                              <Button
                                className="button_common m-0 mb-2 px-4"
                                onClick={() =>
                                  navigate(
                                    `../${AdminRoute.Auth.UpdatesFromTherapist
                                    }?id=${CurrentSession?.theropis?.length &&
                                    CurrentSession?.theropis[0]?._id
                                    }`
                                  )
                                }
                              >
                                View Details
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="hide-updates">
                            <p className="my-2">
                              No Updates from Your Therapist.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-head mt-0 px-3 px-md-0 mb-3">
            <p className="sub-heading">Your Support System</p>
            <p className="sub-lbl m-0">
              A friend in need is a friend in deed, and we intend to be that one
              for you.
            </p>
          </div>

          <div className="row px-3 px-md-0">
            <div
              className="col-md-12 col-lg-4 mb-3 cursor-pointer"
              onClick={() => navigate(`../${AdminRoute?.Auth.AiAsistance}`)}
            >
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={aiTherapist} alt="PlayGround" />
                  </span>
                </div>

                <div className="dashboard-text-div yellow-gradient px-3">
                  <h3 className="secheading mt-3">AI Therapist</h3>
                  <p>
                    Chat anytime with your psychological companion - instant,
                    affordable insights for your peace of mind.
                  </p>

                  <p className="fw-bold cursor_pointer">Know More</p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div
                className="dashboard-inner p-2 h-100 dashboard-inner-boxes"
                onClick={() => navigate(`../${AdminRoute?.Auth?.Groups}`)}
              >
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={groupImg} alt="AiTherapist" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3"> Groups</h3>
                  <p>
                    Find your tribe, share challenges, and unlock strength with
                    other driven professionals.
                  </p>
                  <p className="">
                    <NavLink
                      to={AdminRoute.Auth.Groups}
                      className={"text-decoration-none fw-bold cursor_pointer"}
                    >
                      Know More
                    </NavLink>
                  </p>
                  {/*
                 <div className="row ">
                    <React.Fragment key={1}>
                      <div className="col-3">
                        <span className="group-img position-relative">
                          <img src={groupIcon2} alt="AiTherapist" />
                        </span>
                      </div>
                    </React.Fragment>
                    <div
                      className="col-3 view-all-btn d-flex align-items-center cursor-pointer"
                      onClick={() => navigate(`../${AdminRoute.Auth.Groups}`)}
                    >
                      View All
                    </div>
                  </div>
                */}

                  {/* dashboard 2 Group sections End*/}
                </div>
              </div>
            </div>

            <div
              className="col-md-12 col-lg-4 mb-3"
              onClick={() => navigate(`../${AdminRoute?.Auth.Playground}`)}
            >
              <div className="dashboard-inner p-2 mb-2 h-100 dashboard-inner-boxes">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={playgroundImg} alt="Groups" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3">Playground</h3>
                  <p>
                    Unleash your creativity and recharge your mind with playful
                    exercises designed for peak performance.
                  </p>
                  <p className="">
                    <NavLink
                      to={AdminRoute.Auth.Playground}
                      className={"text-decoration-none fw-bold cursor_pointer"}
                    >
                      Know More
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
