/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
// import { combineDateTime, isBeforeOrAfterCurrent } from "../Common/Function";
import { combineDateTime, isBeforeOrAfterCurrent1Hour } from "../Common/Function";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const MyCalender = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const AppointmentId = queryParams.get("id");
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  // const [value, onChange] = useState(currentDate.toString());
  const [value, onChange] = useState(currentDate);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [therapistData, setTherapishData] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [apiCall, setApiCall] = useState(false);

  useEffect(() => {
    getTherapistDetails();
  }, [value]);





  const getTherapistDetails = () => {
    setLoading(true);
    setError("");
    setSelectedSlot({});
    API.Therapist.getTherpistDetails({
      date: moment(value).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.data.status === 1) {

          setTherapishData(response?.data.data_all[0]);
          console.log(response?.data.data_all[0].theropist_calender.time);
          setTimeSlots(response?.data.data_all[0].theropist_calender.time);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSlotClick = (slot) => {
    if (Object.values(slot)[0]) {
      setSelectedSlot(slot);
    }
  };

  const handleNext = () => {
    setSubmit(true);
    setApiCall(true);
    if (AppointmentId) {
      API.Session.DeleteSession({
        id: AppointmentId,
      })
        .then((response) => {
          if (response.data.status === 1) {
            handelNextApi();
            setSubmit(false);
            setApiCall(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      handelNextApi();
    }
  };

  const handelNextApi = () => {
    if (timeSlots?.length) {
      if (Object.keys(selectedSlot)[0]) {
        setSubmit(true);
        const inputDateTime = combineDateTime(
          moment(value).format("YYYY-MM-DD"),
          Object.keys(selectedSlot)[0]
        );
        let ISTtime = moment(inputDateTime).format("YYYY-MM-DDTHH:mm:ss");
        ISTtime = ISTtime.toLocaleString();
        setError("");
        setApiCall(true);
        API.Session.CreateSession({
          data: {
            clientId: UserData?.userdata?._id,
            theropistId: therapistData._id,
            date: ISTtime + "+00:00",
            timestart: Object.keys(selectedSlot)[0],
            timeend: "11PM",
          },
        })
          .then((response) => {
            if (response.data.status === 1) {
              setSubmit(false);
              setApiCall(false);
              if (AppointmentId) {
                sendResheduleLog();
                toast.success('Appointment Resheduled Successfully');
              }
              navigate(`../${AdminRoute?.Auth?.Dashboard}`);
            }
          })
          .catch((error) => {
            console.log(error);
            setApiCall(false);
          });
      } else {
        setError("Select Time slot");
        setApiCall(false);
      }
    } else {
      setError("Theropist Not Assign");
      setApiCall(false);
    }
  };

  const sendResheduleLog = async () => {
    const planId = sessionStorage.getItem('OrganisationPlanId');
    try {
      const response = await API.ResheduleLimit.LimitSessionReshedule({
        data: {
          userId: UserData?.userdata?._id,
          planId: planId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  // const tileDisabled = ({ date }) => {
  //   // Disable dates that are before today

  //   console.log("tileDisabled  date : ",date);
  //   console.log("tileDisabled  currentDate : ",currentDate);
  //   console.log("date == currentDate : ",date == currentDate);
  //   if(date == currentDate){
  //     debugger
  //     console.log("tileDisabled  date : ",date);
  //     return false;
  //   }
  //   return date < currentDate;
  // };

  const tileDisabled = ({ date }) => {
    // Normalize both dates to the start of the day to only compare date parts
    const startOfDate = new Date(date.setHours(0, 0, 0, 0));
    const startOfCurrentDate = new Date(currentDate.setHours(0, 0, 0, 0));


    if (startOfDate.getTime() === startOfCurrentDate.getTime()) {

      return startOfDate < startOfCurrentDate; // Allow selection of today's date
    }

    return startOfDate < startOfCurrentDate; // Disable past dates
  };

  return (
    <div className="calender-container">
      <img src={rtylifeLogo} className="rylife-log" />
      <div className="cal-header">
        {/* <span>
          <img
            src={backArrow}
            to={navigate(`../${AdminRoute?.Auth?.Registration1}`)}
            className="backarrow"
          />
        </span> */}
        <span onClick={() => navigate(-1)}>
          <img
            src={backBtn}
            style={{
              height: "30px",
              height: "23px",
              marginLeft: "16px",
              opacity: "0.7",
            }}
          />
        </span>
        <p className="header-lbl">Rescheduled Session</p>
      </div>
      <div className="row common-container">
        <div className=" mb-4 col-12 therapist-doctor px-0">
          <div className="inner-div image-div">
            <div className="d-flex align-items-center doctor-img">
              <img
                className="d-block"
                src={
                  therapistData?.image
                    ? therapistData?.image
                    : "https://as2.ftcdn.net/v2/jpg/00/65/77/27/1000_F_65772719_A1UV5kLi5nCEWI0BNLLiFaBPEkUbv5Fv.jpg"
                }
                alt={therapistData?.image}
              />
              <h3 className="ms-2 text-white">{therapistData?.fullname}</h3>
            </div>
            <div className="fst-italic">
              <div className="fst-italic">
                <p className="fw-bold">
                  Experience :{" "}
                  <span className="fw-normal">
                    {therapistData?.experience}Years
                  </span>
                </p>
                <p className="fw-bold mt-2">
                  Expertise :{" "}
                  <span className="fw-normal">{therapistData?.experties}</span>
                </p>
                <p className="fw-bold mt-2">
                  Quote :{" "}
                  <span className="fw-normal">{therapistData?.qote}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 px-0">
          <div className="calendar inner-div">
            <Calendar
              onChange={onChange}
              value={value}
              tileDisabled={tileDisabled}
            // activeStartDate = {new Date()}
            />
          </div>
        </div>
      </div>
      <div className="common-container ">
        <p className="commmon-heading">
          * Tap on the time slot that best suits you
        </p>
        {!loading ? (
          <div className="inner-container slots bg-white">
            {timeSlots &&
              timeSlots.map((slot, index) => {
                return (
                  <>
                    {index !== 0 ? (
                      <>
                        {Object.values(slot)[0] &&
                          // isBeforeOrAfterCurrent(value, Object.keys(slot)[0]) ? (
                          isBeforeOrAfterCurrent1Hour(value, Object.keys(slot)[0]) ? (
                          <button
                            key={index}
                            className={
                              !Object.values(slot)[0]
                                ? "time-picker-button selected"
                                : Object.keys(selectedSlot)[0] ===
                                  Object.keys(slot)[0]
                                  ? "time-picker-button selectnow"
                                  : "time-picker-button"
                            }
                            onClick={() => handleSlotClick(slot)}
                          >
                            {Object.keys(slot)}
                          </button>
                        ) : (
                          <button
                            key={index}
                            className={"time-picker-button selected"}
                          >
                            {Object.keys(slot)}
                          </button>
                        )}
                      </>
                    ) : null}
                  </>
                );
              })}
          </div>
        ) : (
          <div className="container_loader"></div>
        )}
      </div>
      <h3 className="text-danger">{error}</h3>

      <div className="common-container ">
        <button
          type="submit"
          className="button_common w-100 my-3 mb-3 text-center nav_btn"
          role="button"
          disabled={apiCall && submit}
          onClick={(e) => handleNext(e)}
        >
          {apiCall ? <Spinner animation="grow" variant="light" /> : "Set"}
        </button>
      </div>
    </div>
  );
};

export default MyCalender;
