import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "../../assets/images/icons/HomeIcon.png";
import SessionsIcon from "../../assets/images/icons/SessionsIcon.png";
import Groupsicon from "../../assets/images/icons/Groupsicon.png";
import AiIcon from "../../assets/images/icons/AiIcon.png";
import activityListIcon from "../../assets/images/icons/DigitalNotesIcon.png";
import logoutArrow from "../../assets/images/icons/back-arrow.png";

const SideBarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logoutFun = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate(`/login`); 
  };

  const isActive = (path) => {
    if (path === '/groups' && location.pathname.startsWith('/ChatBox')) {
      return true; // Consider /ChatBox as active for groups
    }
    return location.pathname === path;
  };
  

 

  return (
    <div className="side-menu-container">
      <div
        className={`side-manu-item cursor-pointer ${isActive('/') ? 'active-link' : ''}`}
        onClick={() => navigate('/')}
      >
        <img src={HomeIcon} alt="Home" />
        Home
      </div>

      <div
        className={`side-manu-item cursor-pointer ${isActive('/my-activity-list') ? 'active-link' : ''}`}
        onClick={() => navigate('/my-activity-list')}
      >
        <img src={activityListIcon} alt="Digital Notes" />
        Digital Notes
      </div>

      <div
        className={`side-manu-item cursor-pointer ${isActive('/sessions') ? 'active-link' : ''}`}
        onClick={() => navigate('/sessions')}
      >
        <img src={SessionsIcon} alt="Sessions" />
        Sessions
      </div>

      <div
        className={`side-manu-item cursor-pointer ${isActive('/groups') ? 'active-link' : ''}`}
        onClick={() => navigate('/groups')}
      >
        <img src={Groupsicon} alt="Groups" />
        Groups
      </div>

      <div
        className={`side-manu-item cursor-pointer ${isActive('/ai-asistance') ? 'active-link' : ''}`}
        onClick={() => navigate('/ai-asistance')}
      >
        <img src={AiIcon} alt="Ai Assistance" />
        Ai Assistance
      </div>

      <div
        className="side-manu-item cursor-pointer"
        onClick={logoutFun}
      >
        <img src={logoutArrow} alt="Logout" />
        Logout
      </div>
    </div>
  );
};

export default SideBarMenu;
